.header-shadow {
  box-shadow: none;
  border-bottom: 1px solid #d1dae7;
}
@media (min-width: 1024px) {
  .gemEcosystem-text {
    font-size: 1.5rem;
  }
  .header-shadow {
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    border-bottom: none;
  }
  .header-link-wrapper {
    position: relative;
  }
  /* This is the underline element of header links */
  .header-link::after {
    content: "";
    height: 0.25rem;
    width: 0;
    border-radius: 0.125rem;
    background: #023b59;
    position: absolute;
    left: 0;
    bottom: 0.75rem;
    transition: 0.3s ease-out;
    pointer-events: none;
  }
  .header-link-wrapper:hover > .header-link::after {
    width: 100%;
  }
  .header-link-active::after {
    content: "";
    height: 0.25rem;
    width: 100%;
    border-radius: 0.125rem;
    background: #023b59;
    position: absolute;
    left: 0;
    bottom: 0.75rem;
    transition: 0.3s ease-out;
    pointer-events: none;
  }
}
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 100;
}
