.game-changer-background {
  background-image: url("../../../assets/gameChangerDesktopBackgroundImage.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

.game-changer-title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
  color: var(--primary-color);
  text-align: center;
}
@media (min-width: 1024px) {
  .game-changer-title {
    font-size: 2.5rem;
    line-height: 3.125rem;
  }
}
