@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Lexend, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Lexend, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary-color: #023B59 !important;
}
/* @layer base {
  :root {
    --primary-color: #023B59 !important;
  }
} */
html {
  font-size: 12px;
}


@media (min-width: 1280px) {
  html {
    font-size: 16px;
  }
}
