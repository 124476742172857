.data-comparison-hero-section-background {
  background-image: url("../../../assets/uiAutomationHeroSectionMobileBackgroundImage.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 1024px) {
  .data-comparison-hero-section-background {
    background-image: url("../../../assets/uiAutomationHeroSectionDesktopBackgroundImage.png");
  }
}
