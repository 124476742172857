.hero-section-background {
  background: rgba(2, 59, 89, 0.1);
}
.hero-section-heading {
  color: #333;
  font-size: 2rem;
  font-weight: 600;
  line-height: 150%;
}
.hero-section-heading-blue {
  color: var(--primary-color);
}
.hero-section-description {
  color: #333;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
}
