@media (min-width: 1024px) {
  .dotted-line {
    width: 0.15rem;
    height: calc(100% + 3rem);
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    /* trick to make dotted line with desired space between */
    background-image: linear-gradient(#d1dae7 50%, rgba(255, 255, 255, 0) 0%);
    background-position: center;
    background-size: 0.15rem 0.6rem;
    background-repeat: repeat-y;
  }
  .blue-line-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    align-items: end;
  }

  .blue-line {
    width: 0.15rem;
    height: 30vh;
    bottom: 70%;
    left: 50%;
    position: sticky;
    background-color: #023b59;
    z-index: 2;
  }
  .features-avatar-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
  }
  .features-avatar {
    height: 1.75rem;
    width: 1.75rem;
    position: sticky;
    left: 50%;
    top: 30%;
    transform: translate(-50%);
    border-radius: 9999px;
    border: 0.15rem solid #023b59;
    background-color: white;
    z-index: 4;
  }

  .top-gradient-blue-line {
    position: absolute;
    top: 0;
    width: 0.15rem;
    height: 3rem;
    transform: translateX(-50%);
    left: 50%;
    z-index: 5;
  }
  .bottom-absolute-div {
    position: absolute;
    bottom: 0;
    width: 0.15rem;
    height: 3rem;
    transform: translateX(-50%);
    left: 50%;
  }
}
