@media (min-width: 1024px) {
  .circular-ring-wrapper {
    height: 2rem;
    width: 2rem;
    position: absolute;
    z-index: 3;
    transform: translateX(-50%);
    left: 50%;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
  }

  .circular-ring {
    height: 1rem;
    width: 1rem;
    border-radius: 9999px;
    border: 0.15rem solid #d1dae7;
    transition: all 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .circular-ring-dot {
    height: 0.45rem;
    width: 0.45rem;
    border-radius: 9999px;
    background-color: #023b59;
  }
}
