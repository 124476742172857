.sticky-background-wrapper {
  position: sticky;
  top: 0;
  height: 100lvh;
  background-image: url("../../../assets/whatUsersCanDoDesktopBackgroundImage.png");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.what-users-can-do-title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
  color: white;
  text-align: center;
}
@media (min-width: 1024px) {
  .what-users-can-do-title {
    font-size: 2.5rem;
    line-height: 3.125rem;
  }
}
.what-users-can-do-wrapper {
  position: relative;
  margin-top: -100vh;
}
.what-users-can-do-heading {
  color: #fbfbfb;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 150%; /* 2.25rem */
}
.what-users-can-do-description {
  color: #fbfbfb;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.5rem */
}
