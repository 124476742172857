.footer-hr {
  background: rgba(209, 218, 231, 0.8);
}
.footer-hr-desktop {
  background: rgba(51, 51, 51, 0.8);
  height: 0.125rem;
}
.footer-links-heading {
  color: #333333;
  font-family: Lexend;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.6875rem */
}
.footer-links {
  color: #333333;
  font-family: Lexend;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  transition: 0.3s ease-out;
}
.footer-links:hover {
  color: #023b59;
}

.social-media-icons-mobile {
  height: 1.5rem;
  width: 1.5rem;
  fill: white !important;
}
.social-media-icons-desktop {
  transition: 0.3s;
  height: 1.5rem;
  width: 1.5rem;
  fill: black;
}
.social-media-icons-desktop:hover {
  transform: scale(1.5);
  fill: #023b59;
}

/* -------Accordion------- */
.accordion-wrapper {
  background: linear-gradient(3deg, #023b59 -1.94%, #023b59 97.5%);
  padding: 2.5rem 1.5rem;
}

.footer-accordion > .p-accordion-header-link {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  color: #fff !important;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.footer-accordion > .p-accordion-header-link > .p-accordion-header-text {
  color: #fff;
  font-family: Lexend;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150% !important; /* 1.875rem */
}

.footer-accordion > .p-accordion .p-accordion-content {
  padding: 0rem 0rem 1rem 1.25rem !important;
}
.footer-accordion > .p-accordion-content {
  background: transparent !important;
  border: none !important;
  color: rgba(255, 255, 255, 0.95) !important;
  font-family: Lexend;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}
