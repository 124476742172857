.H4 {
  font-family: Lexend;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 3.125rem;
}
.H5 {
  /* H5 */
  font-family: Lexend;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.375rem; /* 118.75% */
}
.H6 {
  /* H6 */
  font-family: Lexend;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem; /* 133.333% */
}

.base-button {
  border-radius: 0.375rem !important;
  padding: 0.75rem 1.5rem !important;
  border: none !important;
  font-family: Lexend !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: 0.05rem;
}

.primary-button {
  background: var(--primary-color) !important ;
  color: #fff !important;
  border: 1px solid var(--primary-color) !important;
}
.primary-button-white {
  background: white !important ;
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
}

.secondary-button {
  background: #fff !important;
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
}
.tertiary-button {
  background: transparent !important;
  color: var(--primary-color) !important;
  border: 1px solid white !important;
}
.tertiary-button-white {
  background: transparent !important;
  color: white !important;
  border: 1px solid white !important;
}
.glow-button {
  transition: 0.3s ease-out !important;
}
.glow-button:hover {
  box-shadow: 0 0rem 0.5rem var(--primary-color) !important;
}
.glow-button:active {
  box-shadow: none !important;
}

.p-button {
  box-shadow: none !important;
  justify-content: center;
}
.p-button-label {
  flex: unset !important;
}

.p-component {
  font-family:
    Lexend,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif !important;
}

.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.component-padding {
  padding: 3rem 1.5rem;
}

@media (min-width: 1024px) {
  .hover-right-arrow::after {
    content: "";
    display: inline-block;
    opacity: 0;
    width: 0;
    height: 0.85219rem;
    background-image: url("./assets/buttonRightArrowIcon.svg");
    background-size: contain;
    background-position-y: 50%;
    background-repeat: no-repeat;
    margin-left: 0.5rem;
    transition: 0.3s ease-out;
  }
  .hover-right-arrow:hover::after {
    width: 1.95119rem;
    opacity: 1;
  }
  .tertiary-button-white:hover {
    background: white !important;
    color: var(--primary-color) !important;
  }
  .primary-button-white:hover {
    background: var(--primary-color) !important ;
    color: #fff !important;
    border: 1px solid var(--primary-color) !important;
  }
  .component-padding {
    padding: 5rem 4rem;
  }
}

/* carousel used in DV page */
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #d9d9d9 !important;
  width: 1rem !important;
  height: 1rem !important;
  border-radius: 50% !important;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: var(--primary-color) !important;
  width: 4rem !important;
  border-radius: 2rem !important;
}
.p-carousel-indicator .p-link:focus {
  box-shadow: none !important;
}
.p-carousel .p-carousel-indicator {
  margin-top: 1rem;
}

.p-tooltip .p-tooltip-text {
  background-color: #d9d9d9;
  color: #333;
}
